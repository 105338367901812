import moment from "moment";

const _accountsIdData = [
  {
    id: 1,
    name: "Rent",
    category: "Net Turnover",
    subCategory: "Rental Income",
    accountNumber: "01010",
    firstSeen: moment(Date.now())
      .subtract("days", Math.floor(Math.random() * 11))
      .utc()
      .format(),
  },
  {
    id: 2,
    name: "Rent stairs",
    category: "Net Turnover",
    subCategory: "Rental Income",
    accountNumber: "01002",
    firstSeen: moment(Date.now())
      .subtract("days", Math.floor(Math.random() * 11))
      .utc()
      .format(),
  },
  {
    id: 3,
    name: "Rent basement",
    category: "Net Turnover",
    subCategory: "Rental Income",
    accountNumber: "01013",
    firstSeen: moment(Date.now())
      .subtract("days", Math.floor(Math.random() * 11))
      .utc()
      .format(),
  },
  {
    id: 4,
    name: "Rent",
    category: "Net Turnover",
    subCategory: "Rental Income",
    accountNumber: "01011",
    firstSeen: moment(Date.now())
      .subtract("days", Math.floor(Math.random() * 11))
      .utc()
      .format(),
  },
  {
    id: 5,
    name: "Commercial rent",
    category: "Net Turnover",
    subCategory: "Rental Income",
    accountNumber: "01021",
    firstSeen: moment(Date.now())
      .subtract("days", Math.floor(Math.random() * 11))
      .utc()
      .format(),
  },
  {
    id: 6,
    name: "Rent reduction",
    category: "Net Turnover",
    subCategory: "Rental Income",
    accountNumber: "01001",
    firstSeen: moment(Date.now())
      .subtract("days", Math.floor(Math.random() * 11))
      .utc()
      .format(),
  },
  {
    id: 7,
    name: "Rent",
    category: "Net Turnover",
    subCategory: "Rental Income",
    accountNumber: "01022",
    firstSeen: moment(Date.now())
      .subtract("days", Math.floor(Math.random() * 11))
      .utc()
      .format(),
  },
];

const _categoriesIdData = [
  {
    id: 1,
    name: "Net Turnover",
  },
  {
    id: 2,
    name: "Interest rates",
  },
];

const _subCategoriesIdData = [
  {
    id: 1,
    name: "Rental Income",
    categoryId: 1,
  },
  {
    id: 2,
    name: "Interest Income",
    categoryId: 2,
  },
];

export const accountsIdData = _accountsIdData;
export const categoriesIdData = _categoriesIdData;
export const subCategoriesIdData = _subCategoriesIdData;
